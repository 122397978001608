import { SubHeading } from "../../components/headings/headings";
import Button from "../../components/button/button";
import Body from "../../components/body/body";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../hooks/use-fetch.hook";
import CheckBoxIcon from "../../assets/checkbox.svg";
import { toast } from "react-toastify";
import AxiosInstance from "../../lib/axios/axios-instance";
import { useState } from "react";

const axiosInstance = AxiosInstance.getInstance();

const ListItem = ({ children }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-5 h-5">
        <img className="max-w-none" src={CheckBoxIcon} alt="checkbox-icon" />
      </div>
      <div>
        <span className="font-normal text-sm text-[#8D8A95]">{children}</span>
      </div>
    </div>
  );
};

const FreePlanButtons = () => {
  return (
    <div>
      <Button bgColor="transparent" textColor="#110C22">
        Continue Free Without Claim
      </Button>
    </div>
  );
};

const PremiumPlanButtons = ({ shopId, onReload }) => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const onPaymentSuccessful = async (response, subscription_id) => {
    try {
      await axiosInstance.patch(`/claim-shops/${shopId}`, {
        metaInfo: {
          isPaid: true,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          razorpay_subscription_id: subscription_id,
        },
      },
      {
        auth: {
          username: process.env.REACT_APP_HTTP_BASIC_USER,
          password: process.env.REACT_APP_HTTP_BASIC_PASS
        }
      }
    );
      
      setLoading(false);
      onReload();
      navigate("/successful-thank-you", {state: {success: true}})
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const onMakePayment = async () => {
    try {
      setLoading(true);
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        toast.error("Razorpay SDK failed to load.");
        setLoading(false);
        return;
      }

      const { data } = await axiosInstance.post("/razorpay/subscriptions");

      if (!data) {
        return toast.error("Error while creating a subscription.");
      }

      const options = {
        subscription_id: data?.id,
        key: data?.key_id,
        description: "Premium Subscription",
        handler: (res) => onPaymentSuccessful(res, data?.id),
        theme: {
          color: "#ff9800",
        },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.on("payment.failed", function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        console.error(response)
        toast.error(response.error.reason || response.error.description);
        setLoading(false);
      });

      razorpay.open();
    } catch (err) {
      toast.error(err?.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col gap-3 w-28">
      <Button disabled={isLoading} bgColor="#0097A7" onClick={onMakePayment}>
        {isLoading ? "Loading..." : "Make Payment"}
      </Button>

    </div>
  );
};

const Plans = ({ shopId, onReload }) => [
  {
    borderColor: "#ECECED",
    bgColor: "transparent",
    type: "Free",
    price: 0,
    listTexts: [
      {
        id: 1,
        text: "Automatic inventory stock creation",
      },
      {
        id: 2,
        text: "Listing your products with tagging to your shop",
      },
      {
        id: 3,
        text: "Discover local shops around you",
      },
    ],
    buttons: <FreePlanButtons />,
  },
  {
    borderColor: "#0097A7",
    bgColor: "rgba(0, 151, 167, 0.2)",
    type: "Premium",
    price: 1999,
    listTexts: [
      {
        id: 1,
        text: "Streamlined Inventory Management: Effortlessly keep track of your inventory through user-generated content as shoppers tag your shop and provide information about their shopping experiences.",
      },
      {
        id: 2,
        text: "Seamless Shopper Connectivity: Take your shop online and reach millions of shoppers who can easily discover and connect with your business.",
      },
      {
        id: 3,
        text: "Enhanced Product Visibility: Improve the visibility of your products by adding detailed tags and descriptions, ensuring that shoppers can easily find your shop among the vast array of options.",
      },
      {
        id: 4,
        text: "Dynamic Offers and Promotions: Enable shoppers to redeem their Dodong coins at your shop and enjoy exclusive discounts and promotions.",
      },
      {
        id: 5,
        text: "Verified Shop Badge: Gain credibility and inspire trust among shoppers with a verified badge for your shop.",
      },
      {
        id: 6,
        text: "Digital Shop Creation: Create a professional and captivating online presence for your shop, attracting a larger audience of potential customers.",
      },
      {
        id: 7,
        text: "Comprehensive Shop Performance Analytics: Access a comprehensive dashboard that offers valuable insights into your shop's performance, empowering you to make informed decisions based on data.",
      },
    ],
    buttons: <PremiumPlanButtons shopId={shopId} onReload={onReload} />,
  },
];

const Data = ({ shopId, onReload }) => {
  return Plans({ shopId, onReload }).map((plan) => {
    return (
      <div
        key={plan.type}
        style={{ borderColor: plan.borderColor }}
        className="flex md:flex-row flex-col gap-5 border rounded-2xl overflow-hidden"
      >
        <div
          style={{ backgroundColor: plan.bgColor }}
          className="flex border-r min-w-96 justify-between items-center py-8 px-6"
        >
          <div className="font-normal text-3xl leading-6">{plan.type}</div>
          <div>
            <span className="font-light text-3xl leading-6">₹</span>
            <span className="font-medium text-3xl leading-6">{plan.price}</span>
            <span className="font-normal text-2xl leading-6">/year</span>
          </div>
        </div>
        <div className="py-6 flex flex-col gap-5 md:px-0 px-6">
          <div className="pr-6 flex flex-col gap-2">
            {plan?.listTexts &&
              plan?.listTexts?.length &&
              plan.listTexts.map((listText) => {
                return <ListItem key={listText.id}>{listText.text}</ListItem>;
              })}
          </div>
          {plan.buttons}
        </div>
      </div>
    );
  });
};

const Subscribe = () => {
  let { shopId } = useParams();

  const { isLoading, data, error, reload } = useFetch({
    url: `/claim-shops/${shopId}`,
  });

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        Loading...
      </div>
    );
  }

  if (!data || error) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        No Shop data available.
      </div>
    );
  }

  if (data?.metaInfo?.isPaid) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        Payment has been processed.
      </div>
    );
  }

  return (
    <Body>
      <div className="flex flex-col justify-center items-center mt-3 gap-6">
        <div className="flex flex-col items-center max-w-[602px] text-center gap-6">
          <h1 className="font-semibold text-5xl">Subscribe to your plan</h1>
          <SubHeading>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.
          </SubHeading>
        </div>

        <div className="w-full flex flex-col gap-6">
          <Data shopId={shopId} onReload={reload} />
        </div>
      </div>
    </Body>
  );
};

export default Subscribe;
