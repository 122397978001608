export const isValidMobileNumber = (mobNumber) => {
  return !(
    !mobNumber ||
    !mobNumber.trim() ||
    !/^\+([1-9]{1})(\d{1,2})([1-9]{1})(\d{9})$/.test(mobNumber)
  );
};

export const getErrorMobileNumber = (payload) => {
  const { shopOwnerContactNumber } = payload;

  if (!isValidMobileNumber(shopOwnerContactNumber)) {
    return "Please provide a valid shop owner contact number with country code without spaces in between.";
  }

  return "";
};

export const getErrorOtpSection = (payload) => {
  const { address, shopOwnerContactNumber } = payload;

  if (!address || !address.trim()) {
    return "Please provide the address.";
  } else if (!isValidMobileNumber(shopOwnerContactNumber)) {
    return "Please provide a valid shop owner contact number with country code.";
  }

  return "";
};

export const getErrorAddDetailsPage = (claimShopDetails) => {
  const {
    address,
    city,
    intro,
    name,
    pin,
    shopOwnerCancelledChequeImage,
    shopOwnerContactNumber,
    shopOwnerName,
    shopType,
    state,
    subCategories,
  } = claimShopDetails;

  if (!name || !name.trim()) {
    return "Please provide the shop name.";
  } else if (!intro || !intro.trim()) {
    return "Please provide the short intro.";
  } else if (!state || !state.trim()) {
    return "Please select the state.";
  } else if (!city || !state.trim()) {
    return "Please select the city name.";
  } else if (!pin || !pin.trim() || !/^[1-9][0-9]{5}$/.test(pin)) {
    return "Please provide a valid pincode.";
  } else if (!address || !address.trim()) {
    return "Please provide the address.";
  } else if (!shopType) {
    return "Please select the shop type.";
  } else if (!subCategories.length) {
    return "Please select at least one sub-category.";
  } else if (!shopOwnerName || !shopOwnerName.trim()) {
    return "Please provide the shop owner name.";
  } else if (!isValidMobileNumber(shopOwnerContactNumber)) {
    return "Please provide a valid shop owner contact number with country code.";
  } else if (!shopOwnerCancelledChequeImage) {
    return "Please provide the shop owner cancelled cheque image.";
  }

  return "";
};
