import { Routes, Route, BrowserRouter, createBrowserRouter } from "react-router-dom";
import Dashboard from "../pages/dashboard/dashboard";
import NavBar from "../components/nav-bar/nav-bar";
import ClaimShop from "../pages/claim-shop/claim-shop";
import AddDetails from "../pages/add-details/add-details";
import StoreFrontPicture from "../pages/store-front-picture/store-front-picture";
import ClaimShopDetails from "../pages/claim-shop-details/claim-shop-details";
import Subscribe from "../pages/subscribe/subscribe";
import SubscribeDummy from "../pages/subscribe-dummy/subscribe-dummy";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "../pages/privacy-policy/privacy-policy";
import TermsAndConditions from "../pages/terms-and-conditions/terms-and-conditions";
import RefundAndCancellation from "../pages/refund-and-cancellation/refund-and-cancellation";
import ContactUs from "../pages/contact-us/contact-us";
import ShippingPolicy from "../pages/shipping-policy/shipping-policy";
import LandingPage from "../pages/landing-page/landing-page";
import {Outlet} from "react-router-dom";
import AboutUs from "../pages/about-us/about-us";
import PricingPolicy from "../pages/pricing-policy/pricing-policy";
import Demo from "../pages/demo/demo";
import ThankYou from "../pages/thank-you/thank-you";
import RegistrationEnquiry from "../pages/registration-enquiry/registration-enquiry";



function NavbarWrapper(){
    return (
    <div>
        <NavBar/>
        <Outlet/>
    </div>
    )
};

const Root = () => {
  return (
    <BrowserRouter basename="/">
      <ToastContainer limit={1} />
      
      <Routes>
        <Route path="/" Component={LandingPage} />
        <Route path="/" element={<NavbarWrapper />}>
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="instructions" element={<ClaimShop/>} />
          <Route path="add-shop" element={<AddDetails/>} />
          <Route path="add-store-front-picture" element={<StoreFrontPicture/>} />
          <Route path=":shopId/shop" element={<ClaimShopDetails/>} />
          <Route path=":shopId/subscribe" element={<Subscribe/>} />
          <Route path="/successful-thank-you" element={<ThankYou/>} />
          <Route path="privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="terms-and-conditions" element={<TermsAndConditions/>} />
          <Route path="registration-enquiry" element={<RegistrationEnquiry/>} />
          <Route
            path="refund-and-cancellation-policy"
            element={<RefundAndCancellation/>}
          />
          <Route path="contact-us" element={<ContactUs/>} />
          <Route path="shipping-policy" element={<ShippingPolicy/>} />
          <Route path="about-us" element={<AboutUs/>} />
          <Route path="pricing-policy" element={<PricingPolicy/>} />
          <Route path="services" element={<SubscribeDummy/>} />
          <Route path=":shopId/demo" element={<Demo/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Root;
