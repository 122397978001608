import TwitterIcon from '../../assets/twitter.svg'
import EmailIcon from '../../assets/sms.svg'
import LinkedinIcon from '../../assets/linkedin.svg'
import DiscordIcon from '../../assets/discord.svg'
import ArrowIcon from '../../assets/down.svg'
import Discover1Img from '../../assets/discover1.svg'
import Discover2Img from '../../assets/discover2.svg'
import Discover3Img from '../../assets/discover3.svg'
import StandOutImg from '../../assets/stand-out.svg'
import StandOutMobileImg from '../../assets/stand-out-mobile.svg'
import BoxesIcon from '../../assets/boxes.svg'
import ClipboardIcon from '../../assets/clipboard-tick.svg'
import ConnectIcon from '../../assets/connect.svg'
import ChartIcon from '../../assets/presention-chart.svg'
import ArrowOutwardIcon from '../../assets/arrow-outward.svg'
import ArrowOutwardDarkIcon from '../../assets/arrow-outward-dark.svg'
import Logo1 from '../../assets/1.svg'
import Logo2 from '../../assets/2.svg'
import Logo3 from '../../assets/3.svg'
import Logo4 from '../../assets/4.svg'
import Logo5 from '../../assets/5.svg'
import Logo6 from '../../assets/6.svg'
import Background from '../../assets/background.png'
import BackgroundMobile from '../../assets/bg-mobile.png'
import HeroImage from '../../assets/hero-image.png'
import { useNavigate } from "react-router-dom";
import MenuIcon from "../../assets/menu.svg";
import MoreIcon from "../../assets/More.png";
import UsersIcon from "../../assets/users.png";
import GalleryAddIcon from "../../assets/gallery-add.png";
import GlobalIcon from "../../assets/global.png";

import "./button.scss";
import Logo from "../../assets/logo.svg";
import { useState } from 'react'
import { DisplayNavItem } from '../../components/nav-bar/nav-bar'


const SocialButton = ({src, bgColor = '#FFFFFF', href}) => {
    return (<div style={{backgroundColor: bgColor }} className="w-10 h-10 flex justify-center items-center rounded-3xl">
                <a href={href} target="_blank" rel="noopener noreferrer">
                        <img src={src} alt="Email" />
                </a>
            </div>)
}


const Button = ({children, handleCLick}) => {
    const navigate = useNavigate()
    const onClick = () => {
        if(handleCLick) return handleCLick()

        navigate('/registration-enquiry')
    }
    return (
        <button onClick={onClick}
            style={{ backgroundColor: "rgb(255, 152, 0)", color: "white" }}
            className="ui-btn-2 font-medium text-lg rounded-xl py-5 px-4 h-full w-full flex justify-center items-center" >
            {children}
        </button>
    )
}


const SubscribeNow = () => {
    const navigate = useNavigate();

    const navigateTo = (path) => navigate(path)
    return <div className="lg:px-28 pb-10 pt-24">
        <div className="bg-[#121212] px-10 flex flex-col gap-7 items-center justify-center lg:rounded-3xl lg:px-80 py-16">
            <div className="font-medium text-4xl text-[#FFFFFF] text-center">Subscribe now Get Started Today - It’s Quick & Easy!</div>
            <div className="lg:w-[188px] lg:h-[56px]">
                <Button handleCLick={() => navigateTo('/instructions')}>
                    <div className="flex items-center gap-2">
                        <div>Subscribe Now</div>
                        <div><img src={ArrowOutwardIcon} alt="Arrow-Outward-Icon"/></div>
                    </div>
                </Button>
            </div>
        </div>
    </div>
}

const Footer = () => {
    return (<footer className="bg-black  lg:px-7 py-5">
                <div className='pt-14 px-10 lg:px-28'>
                    <div className="flex flex-col-reverse lg:flex-row justify-between pb-6">
                        <div className="flex flex-col gap-10">
                            <div className='hidden lg:block'><img src={Logo} alt="logo" /></div>

                            <div className="flex flex-col">
                                <div className="py-2"><label className="text-[#FFFFFF] font-light text-base leading-5">Follow our latest news</label></div>
                                <div>
                                    <div className="bg-[#252525] border-[#929292] border flex items-center rounded-lg lg:w-[402px] justify-between">
                                        <input placeholder="Enter email address" className="bg-transparent pl-6 focus:text-white text-white"/>
                                        <div className="w-[99px] mx-[4px] my-[4px]">
                                            <Button>
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-16">
                            <div className="flex flex-col gap-4">
                                <Link><span className="text-[#FFFFFF] font-medium text-xl leading-8">Explore</span></Link>
                                <Link path="/about-us"> <span className="text-[#a0a0a0] font-normal text-base leading-6">About</span></Link>
                                <Link path="/services"><span className="text-[#a0a0a0] font-normal text-base leading-6">Services</span></Link>
                                <Link path="/contact-us"><span className="text-[#a0a0a0] font-normal text-base leading-6">Contact</span></Link>
                            </div>
                            <div className="flex flex-col gap-4">
                                <Link><span className="text-[#FFFFFF] font-medium text-xl leading-8">Privacy</span></Link>
                                <Link path="/privacy-policy"><span className="text-[#a0a0a0] font-normal text-base leading-6">Privacy Policy</span></Link>
                                <Link path="/pricing-policy"><span className="text-[#a0a0a0] font-normal text-base leading-6">Pricing Policy</span></Link>
                                <Link path="/terms-and-conditions"><span className="text-[#a0a0a0] font-normal text-base leading-6">Terms of Use</span></Link>
                                <Link path="/shipping-policy"><span className="text-[#a0a0a0] font-normal text-base leading-6">Shipping Policy</span></Link>
                                <Link path="/refund-and-cancellation-policy"><span className="text-[#a0a0a0] font-normal text-base leading-6">Cancellation and Refund Policy</span></Link>
                            </div>
                        </div>
                    </div>
                    <div style={{borderColor:'#2a2a2a'}} className='flex flex-col-reverse gap-3 lg:gap-0 lg:flex-row lg:items-center lg:justify-between border-t-[1px] pt-7'>
                        <div className="text-[#a0a0a0]">Dodong. 2023. All Right Reserved.</div>
                        <div className="text-[#FFFFFF]">Made with 🤍</div>
                        <div className='flex items-center justify-between'>
                        <div className='flex gap-3'>
                            <SocialButton src={EmailIcon} href="mailto:support@dodong.com"/>
                            <SocialButton src={TwitterIcon} href="mailto:support@dodong.com"/>
                            <SocialButton src={LinkedinIcon} href="https://www.linkedin.com/company/dodongpvtltd/"/>
                        </div>
                        <div className='block lg:hidden'><img src={Logo} alt="logo" /></div>
                        </div>
                    </div>
                </div>
            </footer>)
}


const FAQQuestionAns = ({question, answer}) => {
    const [displayAns, setDisplayAns] = useState(false)

    const toggleDisplay = () => setDisplayAns(!displayAns)

    const buttonClass = displayAns ? 'transition-transform duration-300 rotate-0' :'transition-transform duration-300 rotate-180'
    return (
    <div className="gap-16 justify-between border border-[#E7E7E7] rounded-2xl p-6 lg:w-[600px] items-start">
        <div>
            <div className="text-[#110C22] text-xl font-medium flex justify-between items-center">
                <div className="flex-1">{question}</div>

                <div className="w-[24px] h-[24px]">
                    <button className={buttonClass} onClick={toggleDisplay}><img className="w-[24px] h-[24px]" src={ArrowIcon} alt="arrow"/></button>
                </div>
            </div>
            {displayAns && (
            <div className="text-[#8D8A95] text-base font-normal ">
                {answer}
            </div>)}
        </div>
        
    </div>
    )
}

const FAQ = () => {
    return (
        <div className="py-14 lg:px-28 flex lg:flex-row flex-col gap-10 lg:gap-3 justify-between pt-14 px-10">
            <div className="flex flex-col gap-2">
                <div className="text-[#110C22] text-4xl font-semibold">Frequently Asked Questions</div>
                <div className="text-[#8D8A95] text-xl font-normal">Unanswered questions?</div>
                <div className="text-[#8D8A95] text-xl font-normal">Contact us at <a href="mailto:dodong.business@gmail.com">dodong.business@gmail.com</a></div>
                <div className="flex gap-2 mt-3">
                    <SocialButton href="mailto:support@dodong.com" bgColor="#EDEDED" src={EmailIcon}/>
                    <SocialButton bgColor="#EDEDED" src={DiscordIcon}/>
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <FAQQuestionAns 
                    question="How do I list my shop on Dodong?" 
                    answer={
                        <>
                        <div>Listing your shop on Dodong is simple! Follow these steps:</div>
                        <ul className="list-disc pl-4">
                            <li>Sign up for a Dodong account.</li>
                            <li>Provide essential details about your shop, such as location, and contact information.</li>
                            <li>Upload high-quality images of your shop</li>
                            <li>Voilà! Your shop is now live on Dodong.</li>
                        </ul>
                        </>
                    }
                />

                <FAQQuestionAns 
                    question="What makes Dodong different from other platforms?" 
                    answer={
                        <>
                        <div>Dodong stands out because:</div>
                        <ul className="list-disc pl-4">
                            <li>Our AI-powered recommendations ensure your shop reaches potential customers.</li>
                            <li>User-generated content showcases your unique products without inventory hassles.</li>
                            <li>Dodong rewards shoppers with cash discounts, driving more sales.</li>
                        </ul>
                        </>
                    }
                />

                <FAQQuestionAns 
                    question="Can I track my shop’s performance on Dodong?" 
                    answer={<span>Absolutely! Dodong provides analytics on customer behavior, views, and sales. Monitor your shop’s performance and make data-driven decisions.</span>}
                />

                <FAQQuestionAns 
                    question="Is Dodong suitable for small businesses?" 
                    answer={<span>Yes! Dodong caters to all types of businesses, from small boutiques to large retailers. Our platform levels the playing field and helps you compete effectively.</span>}
                />

                 <FAQQuestionAns 
                    question="How do I earn credibility on Dodong?" 
                    answer={<span>Aim for positive reviews and ratings from satisfied customers. The verified badge also adds credibility to your shop.</span>}
                />

                 <FAQQuestionAns 
                    question="What’s the cost of using Dodong?" 
                    answer={<span>Dodong charges a minimal one-time yearly subscription fee. No hidden costs—just transparent pricing.</span>}
                />

            </div>
        </div>
    )
}

const DiscoverCard = ({
    bgColor,
    borderColor,
    imgSrc,
    title,
    desc,
    imgDesc
}) => {
    const width = '346px'
    const height = '388px'
    return (
        <div 
            style={{
                backgroundColor: bgColor, 
                borderColor: borderColor
            }} 
            className="border rounded-2xl flex flex-col items-center justify-between w-full lg:w-[346px] h-[388px]">
            <div className=" py-5 text-center">
                <div className="text-[#000000] px-14 text-2xl font-semibold">{title}</div>
                {/* <div className="text-[#000000] px-12 text-xl font-normal">{desc}</div> */}
            </div>
            <div>
                <img src={imgSrc} alt={imgDesc} />
            </div>
        </div>
    )
}


const Discover = () => {
    return (
        <div className="bg-[#F8F8FB] pt-28 pb-7 px-10 lg:px-28 gap-16 flex flex-col">
            <div className="text-center">
                <div className="lg:px-64 font-semibold text-[#110C22] text-4xl">With Dodong, your business isn’t just listed—it’s discovered and thriving!</div>
                {/* <p className="font-normal text-[#8D8A95] text-xl">Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no </p> */}
            </div>
            <div className="flex lg:flex-row flex-col justify-between items-center lg:gap-0 gap-10">
                <DiscoverCard
                    bgColor="#dfedf3"
                    borderColor="#0097a7"
                    title="Quick Shop Setup"
                    desc="Et has minim elitr intellegat"
                    imgDesc="shop-listing"
                    imgSrc={Discover1Img}
                />    

                <DiscoverCard
                    bgColor="#f3f5f7"
                    borderColor="#cccccc"
                    title="User-Generated Content"
                    desc="Et has minim elitr intellegat"
                    imgDesc="local-product"
                    imgSrc={Discover2Img}
                />

                 <DiscoverCard
                    bgColor="#f7f1e5"
                    borderColor="#f3ba2d"
                    title="Cash Discounts"
                    desc="Et has minim elitr intellegat"
                    imgDesc="reedem"
                    imgSrc={Discover3Img}
                />

            </div>
        </div>
    )
}

const StandOut = () => {
    return (
        <div className="px-10 lg:px-28 flex flex-col lg:flex-row pt-28 gap-5">
            <div className="flex flex-col gap-7">
                <div className="flex flex-col gap-3">
                    <div className="font-semibold text-[#110C22] text-4xl">Tired of being overlooked? Stand out with Dodong!</div>
                    {/* <p className="font-normal text-[#8D8A95] text-xl">Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no </p> */}
                </div>
                <div className="w-[200px]"><Button>
                    <div className="flex items-center gap-2">
                        <div>Get Started Now</div>
                        <div><img src={ArrowOutwardIcon} alt="Arrow-Outward-Icon"/></div>
                    </div>
                    </Button></div>
                <div className="flex flex-col lg:flex-wrap gap-3 mt-5 gap-x-3 gap-y-7">
                    <div className="w-full lg:w-[350px] flex flex-col gap-3">
                        <div className="w-[48px] h-[48px] border border-[#e6e6e6] flex justify-center items-center rounded-full"><img src={ChartIcon} alt="Chart-Icon" /></div>
                        <p className="text-[#110C22] text-2xl font-normal leading-7">Instant online shop setup in just 2 minutes.</p>
                    </div>

                    <div className="w-full lg:w-[350px] flex flex-col gap-3">
                        <div className="w-[48px] h-[48px] border border-[#e6e6e6] flex justify-center items-center rounded-full"><img src={ConnectIcon} alt="products" /></div>
                        <p className="text-[#110C22] text-2xl font-normal leading-7">User-generated content showcases your products.</p>
                    </div>

                    <div className="w-full lg:w-[350px] flex flex-col gap-3">
                        <div className="w-[48px] h-[48px] border border-[#e6e6e6] flex justify-center items-center rounded-full"><img src={ClipboardIcon} alt="check-Icon" /></div>
                        <p className="text-[#110C22] text-2xl font-normal leading-7">AI-powered recommendations drive traffic to your shop.</p>
                    </div>

                    <div className="w-full lg:w-[350px] flex flex-col gap-3">
                        <div className="w-[48px] h-[48px] border border-[#e6e6e6] flex justify-center items-center rounded-full"><img src={BoxesIcon} alt="More-Icon" /></div>
                        <p className="text-[#110C22] text-2xl font-normal leading-7">Cash discounts incentivize purchases and repeat customers.</p>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative">
                <img src={StandOutImg} alt="stand-out-img" />
                <div style={{background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 5.48%, #FFFFFF 100%)`}} className="absolute h-[155px] w-full bottom-0"></div>
            </div>
            <div className="block relative lg:hidden">
                <img className="w-full" src={StandOutMobileImg} alt="stand-out-img" />
                <div style={{background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 5.48%, #FFFFFF 100%)`}} className="absolute h-[155px] w-full bottom-0"></div>
            </div>
        </div>
    )
}

const Logos = [
    {
        bgColor: '#e7ebf3',
        imgSrc: Logo3
    },
    {
        bgColor: '#e9eaea',
        imgSrc: Logo4
    },
    {
        bgColor: '#fef8ea',
        imgSrc: Logo2
    },
    {
        bgColor: '#e9f7ec',
        imgSrc: Logo1
    },
    {
        bgColor: '#e9eaea',
        imgSrc: Logo5
    },
    {
        bgColor: '#e5eeff',
        imgSrc: Logo6
    },
]

const LogoWrapper = ({bgColor, imgSrc}) => {
    return (
    <div style={{backgroundColor: bgColor}} className="lg:w-[159px] lg:h-[152px] rounded-2xl border border-[#dce7d9] flex justify-center items-center">
        <div><img className="px-3 py-3 lg:px-0 lg:py-0 w-[82px] h-[82px]" src={imgSrc} alt="logo" /></div>
    </div>)
}

const LogoGroup = ({FirstLogo, SecondLogo, ThirdLogo, FourthLogo, isMarqueeRev = false}) => {
    const marquee = isMarqueeRev ? 'animate-marquee_rev' : 'animate-marquee'
    const marquee2 = isMarqueeRev ? 'animate-marquee_rev2' : 'animate-marquee2'
    return (
    <div className="relative flex overflow-x-hidden hide-scrollbar">  
        <div className={`flex flex-col gap-6 lg:h-[680px] -translate-y-7 overflow-hidden ${marquee} hide-scrollbar`}>
            <LogoWrapper imgSrc={FirstLogo.imgSrc} bgColor={FirstLogo.bgColor} />  
            <LogoWrapper imgSrc={SecondLogo.imgSrc} bgColor={SecondLogo.bgColor}  />            
            <LogoWrapper imgSrc={ThirdLogo.imgSrc} bgColor={ThirdLogo.bgColor}  />     
            <LogoWrapper imgSrc={FourthLogo.imgSrc} bgColor={FourthLogo.bgColor}  />                   
        </div>
        <div className={`flex mt-7 flex-col gap-6 lg:h-[680px] -translate-y-7 overflow-hidden absolute top-0 ${marquee2} hide-scrollbar`}>
            <LogoWrapper imgSrc={FirstLogo.imgSrc} bgColor={FirstLogo.bgColor} />  
            <LogoWrapper imgSrc={SecondLogo.imgSrc} bgColor={SecondLogo.bgColor}  />            
            <LogoWrapper imgSrc={ThirdLogo.imgSrc} bgColor={ThirdLogo.bgColor}  />     
            <LogoWrapper imgSrc={FourthLogo.imgSrc} bgColor={FourthLogo.bgColor}  />                   
        </div>
    </div>
    )
}

const Join = () => {
    const navigate = useNavigate()
    const onClick = () => {
        navigate('/registration-enquiry')
    }

    return (
        <div className="bg-[#fafafa] px-10 lg:px-28 flex flex-col lg:gap-0 gap-10 lg:flex-row justify-between lg:h-[621px] overflow-y-hidden">
            <div className="flex flex-col gap-3 pt-10 lg:pt-28 lg:w-[455px]">
                <div className="text-[#110C22] text-4xl font-normal">
                    Join these successful businesses who trust Dodong
                </div>
                <div className="text-[#8D8A95] text-xl font-normal">Join hundreds of satisfied shop owners who’ve seen their sales soar with Dodong!</div>
                <div>
                    <button onClick={onClick} className="border-[#110C22] border rounded-xl px-4 py-5" >
                        <div className="flex items-center gap-2">
                            <div>Get Started Now</div>
                            <div><img src={ArrowOutwardDarkIcon} alt="Arrow-Outward-Dark-Icon" /></div>
                        </div>
                    </button>
                </div>
            </div>
            <div className="flex gap-6 justify-center">
                <LogoGroup FirstLogo={Logos[0]} SecondLogo={Logos[1]} ThirdLogo={Logos[2]} FourthLogo={Logos[3]} />
                <LogoGroup FirstLogo={Logos[3]} SecondLogo={Logos[4]} ThirdLogo={Logos[1]} FourthLogo={Logos[4]} isMarqueeRev={true}/>
                <LogoGroup FirstLogo={Logos[2]} SecondLogo={Logos[5]} ThirdLogo={Logos[0]} FourthLogo={Logos[2]} />
            </div> 
        </div>
    )
}

const Link = ({ children, path }) => {

  const navigate = useNavigate();

  const onClick = () => {
    if(!path) return
    navigate(path)
  }

  return (
    <button className="flex items-center justify-start" onClick={onClick} >
      {children}
    </button>
  );
};

const NavItemList = [
  {
    name: "About Us",
    iconImg: UsersIcon,
    id: 1,
    path:'/about-us'
  },
  {
    name: "Blogs",
    iconImg: GlobalIcon,
    id: 2,
  },
  {
    name: "Security",
    iconImg: UsersIcon,
    id: 3,
  },
  {
    name: "Pricing",
    iconImg: MoreIcon,
    id: 4,
    path:'/pricing-policy'
  },
  {
    name: "Our MPC",
    iconImg: UsersIcon,
    id: 5,
  },
  {
    name: "Official Video",
    iconImg: GalleryAddIcon,
    id: 6,
    link:'https://www.instagram.com/reel/C8FJKOAvZrR/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=='
  },
  {
    name: "Login",
    iconImg: MoreIcon,
    id: 7,
  },
  {
    name:"Sign Up",
    iconImg: MoreIcon,
    id: 8,
  }
];


const NavBarLandingPage = () => {
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => setShowMenu(!showMenu);

    return (
        <header>
            <div className="flex justify-between px-10 py-10 relative">
                <Link to="/">
                <img src={Logo} alt="logo" />
                </Link>
                <div className="flex justify-end w-24">

                <div className="relative">
                    <button
                    onClick={toggleMenu}
                    className="p-3.5 h-10 w-10 bg-white rounded-lg border-b flex justify-center items-center bg-transparent"
                    >
                    <img src={MenuIcon} alt="MenuIcon" className="max-w-none" />
                    </button>

                    {showMenu && (
                    <div className="transition-opacity bg-white absolute min-w-72 py-5 border border-[#F3F3F4] rounded-2xl flex flex-col gap-3 justify-center right-0 top-12 z-50">
                        <DisplayNavItem navItemList={NavItemList} toggleMenu={toggleMenu}/>
                    </div>
                    )}
                </div>
                </div>
            </div>
        </header>
    )
}

const HeroSectionBody = () => {
    return (
    <div className="flex items-center">
        <div className="flex flex-col gap-5 w-full lg:w-[550px] lg:h-[380px]">
            <div className="text-[2.5rem] lg:text-6xl font-normal text-[#FFFFFF]">Elevate Your Business with Dodong!</div>
            <div className="text-[#b3b7ab] text-xl font-normal">Seamless Integration, Unmatched Reach</div>

            <div className="lg:w-[200px] lg:h-[56px] w-full h-auto">
                <Button>
                    <div className="flex items-center gap-2">
                        <div>Get Started Now</div>
                        <div><img src={ArrowOutwardIcon} alt="Arrow-Outward-Icon"/></div>
                    </div>
                </Button>
            </div>
            <div className="font-normal text-sm text-[#b2b7ac]">Join the Revolution!</div>
        </div>
        <div className="hidden lg:block h-[531px] w-[572.86px]">
            <img src={HeroImage} alt="landing-page"/>
        </div>
    </div>
    )
}

const HeroSection = () => {
    
    return (
        <>
        <div className="px-0 block lg:hidden" style={{background: `url(${BackgroundMobile})`, backgroundPosition:'center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
            <div className='relative block'>
                <NavBarLandingPage />
                <div className="thin-line absolute bottom-0 w-full"></div>
            </div>
            <div className="px-10 relative py-10">
                <HeroSectionBody/>
            </div>
        </div>

        <div className="hidden lg:block px-28 h-[901px]" style={{background: `url(${Background})`, backgroundPosition:'center', backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
            <div className="py-9 flex items-center justify-between">
                <div className="flex items-center gap-12">
                    <div><Link path="/"><img src={Logo} alt="logo" /></Link></div>
                    <div className="flex items-center gap-6">
                        <Link path="/about-us"><span className="text-[#b7bcbc] text-base font-normal">About Us</span></Link>
                        <Link path="/"><span className="text-[#b7bcbc] text-base font-normal">Blogs</span></Link>
                        <Link path="/"><span className="text-[#b7bcbc] text-base font-normal">Security</span></Link>
                        <Link path="/pricing-policy"><span className="text-[#b7bcbc] text-base font-normal">Pricing</span></Link>
                        <Link path="/"><span className="text-[#b7bcbc] text-base font-normal">Our MPC</span></Link>
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <div className="text-[#FFFFFF] text-base font-normal underline">Login</div>
                    <div className="bg-white px-3 py-5 rounded-full w-[95px] flex items-center justify-center h-[48px]">
                        <div className="text-[#051A1B] text-base font-normal">Sign Up</div>
                    </div>
                </div>
            </div>
            <div className="relative mt-3">
                <div className="thin-line absolute top-[5px] w-full"></div>
                <HeroSectionBody />
                <div className="thin-line absolute bottom-[2px] w-full"></div>
            </div>
        </div>
        </>
    )
}





const LandingPage = () => {
    return (
        <div>
            <HeroSection/>
            <Join/>
            <StandOut />
            <Discover />
            <SubscribeNow />
            <FAQ />
            <Footer />
        </div>
    )
}

export default LandingPage