export const CustomHeightContainer = ({ children }) => {
  return <div className="custom-container overflow-x-auto">{children}</div>;
};

const Body = ({ children }) => {
  return (
    <div className="md:pt-6 md:pr-14 md:pb-0 md:pl-14 pt-6 pr-3 pb-0 pl-3">
      {children}
    </div>
  );
};

export default Body;
