import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  validMobileNumber: [],
};

const registrationEnquirySlice = createSlice({
  name: "validMobileNumber",
  initialState,
  reducers: {
    updateValidMobileNumber: (state, action) => {
      state.validMobileNumber = action.payload.validMobileNumber;
    },
    clearValidMobileNumber: () => initialState,
  },
});

export const { updateValidMobileNumber, clearValidMobileNumber } =
  registrationEnquirySlice.actions;

export default registrationEnquirySlice.reducer;
