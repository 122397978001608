import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import claimShopReducer from "../slices/claim-shop.slice";
import savedShopReducer from "../slices/saved-shop.slice";
import locationReducer from "../slices/location.slice";
import validMobileNumberReducer from "../slices/valid-mobile-number.slice";
import registrationEnquiryReducer from "../slices/registration-enquiry.slice";

const rootReducer = combineReducers({
  claimShop: claimShopReducer,
  savedShop: savedShopReducer,
  location: locationReducer,
  validMobileNumber: validMobileNumberReducer,
  registrationEnquiry: registrationEnquiryReducer
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["savedShop", "validMobileNumber", "registrationEnquiry"],
  blacklist: ["claimShop", "location"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const appStore = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(appStore);
