import { Heading } from "../../components/headings/headings";
import { useNavigate } from "react-router-dom";
import KeyIcon from "../../assets/key.svg";
import LocationIcon from "../../assets/location-2.svg";
import ShopIcon from "../../assets/shop.svg";
import Body, { CustomHeightContainer } from "../../components/body/body";
import Footer from "../../components/footer/footer";
import "./claim-shop.scss";

const GridColumn = ({ image, text }) => {
  return (
    <div className="flex flex-col items-center md:justify-center md:items-start">
      <div className="border rounded-2xl flex justify-center items-center w-20 h-20">
        <img src={image} alt="key-icon" />
      </div>
      <div className="pt-5">
        <p className="font-normal text-xl grid-text text-[#051A1B]">{text}</p>
      </div>
    </div>
  );
};

const NumberedPoint = ({ serialNum, text }) => {
  return (
    <div className="flex items-center">
      <div className="rounded-[100px] bg-slate-100 py-2 px-2.5 border-[#F3F3F4] h-8 w-8 flex justify-center items-center">
        <span className="font-medium text-sm text-[#8D8A95]">0{serialNum}</span>
      </div>
      <div className="ml-4">
        <p className="font-normal text-sm text-[#8D8A95]">{text}</p>
      </div>
    </div>
  );
};

const ClaimShop = () => {
  const navigate = useNavigate();

  const onCancel = () => {
    navigate("/");
  };

  const onNext = () => {
    navigate("/add-shop");
  };

  return (
    <>
      <CustomHeightContainer>
        <Body>
          <div>
            <Heading>Claim Shop - Instructions</Heading>
            <div className="md:grid md:grid-cols-3 md:gap-2 md:pt-5 flex flex-col gap-5 mt-5">
              <GridColumn
                image={ShopIcon}
                text="Your business name and category should match your Business Profile.
        Example: business card, branded vehicle, business tools or equipment"
              />

              <GridColumn
                image={LocationIcon}
                text="Your location should match your Business Profile service area.
            Example: street sign, nearby businesses, surrounding area"
              />

              <GridColumn
                image={KeyIcon}
                text="You’re authorised to represent this business. Example: unlocking
            branded vehicle, operating online business tools, proof of address"
              />
            </div>

            <div className="mt-10">
              <div className="font-semibold text-sm">
                Tips for getting started
              </div>
              <div className="mt-2 gap-2 flex flex-col">
                <NumberedPoint
                  serialNum={1}
                  text="Plan ahead. Make sure you capture all 3 requirements in 1 continuous
          recording."
                />
                <NumberedPoint
                  serialNum={2}
                  text="Take your time. Most successful videos are 1-2 minutes. Videos longer
          than 5 minutes won't be accepted."
                />
                <NumberedPoint
                  serialNum={3}
                  text="Start outside. Capture your business surroundings first before moving inside."
                />
              </div>
            </div>
          </div>
        </Body>
        <Footer onNext={onNext} onCancel={onCancel} />
      </CustomHeightContainer>
      
    </>
  );
};

export default ClaimShop;
